import { useTheme } from '@emotion/react';
import React from 'react';
import Giscus from "../giscus";

function Comments({ repo }) {
  const { themeMode } = useTheme();

  return (
    <Giscus
      repo={repo}
      repoId="R_kgDOGtKTXg"
      category="Comments"
      categoryId="DIC_kwDOGtKTXs4CVtVk"
      mapping="pathname"
      term="comment"
      strict="0"
      reactionsEnabled="1"
      emitMetadata="1"
      inputPosition="bottom"
      theme={themeMode}
      lang="ko"
      loading="lazy"
      crossorigin="anonymous"
    />
  );
}

export default Comments;
