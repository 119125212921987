import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layout';
import Seo from '../components/seo';
import PostHeader from '../components/post-header';
import PostNavigator from '../components/post-navigator';
import Post from '../models/post';
import PostContent from '../components/post-content';
import Comments from '../components/comments';
import AuthorDescription from '../components/author-description';
import Toc from '../components/toc';
import { generateOgImageMetaTags, OG_IMAGE_PATH } from '../utils/ogImage';

function BlogTemplate({ data }) {
  const curPost = new Post(data.cur);
  const prevPost = data.prev && new Post(data.prev);
  const nextPost = data.next && new Post(data.next);
  const tableOfContents = data.cur.tableOfContents;
  const { comments } = data.site?.siteMetadata;
  const commentRepo = comments?.giscus?.repo;

  const ogImage = curPost?.slug
    ? `${data.site.siteMetadata.siteUrl}/${OG_IMAGE_PATH(curPost.slug)}`
    : '';

  const meta = !ogImage ? [] : generateOgImageMetaTags(ogImage);

  return (
    <Layout>
      <Seo
        title={curPost?.title}
        description={curPost?.description || curPost?.excerpt}
        meta={meta}
      />
      <PostHeader post={curPost} />
      <PostContent html={curPost.html} />
      <Toc tocContents={tableOfContents} />
      <AuthorDescription author={curPost.author} />
      {commentRepo && <Comments repo={commentRepo} />}
      <PostNavigator prevPost={prevPost} nextPost={nextPost} />
    </Layout>
  );
}

export default BlogTemplate;

export const pageQuery = graphql`
  query ($slug: String, $nextSlug: String, $prevSlug: String) {
    cur: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      tableOfContents
      html
      excerpt(pruneLength: 100, truncate: true)
      timeToRead
      frontmatter {
        date(formatString: "YYYY.MM.DD")
        description
        title
        categories
        author
        emoji
        tags
        github
      }
      fields {
        slug
      }
    }

    next: markdownRemark(fields: { slug: { eq: $nextSlug } }) {
      id
      html
      frontmatter {
        date(formatString: "YYYY.MM.DD")
        title
        categories
        author
        emoji
      }
      fields {
        slug
      }
    }

    prev: markdownRemark(fields: { slug: { eq: $prevSlug } }) {
      id
      html
      frontmatter {
        date(formatString: "YYYY.MM.DD")
        title
        categories
        author
        emoji
      }
      fields {
        slug
      }
    }

    site {
      siteMetadata {
        siteUrl
        comments {
          giscus {
            repo
          }
        }
      }
    }
  }
`;
