import React from 'react';
import './style.scss';

export default function AuthorDescription({ author: authors }) {
  return (
    <section className="author-descriptions">
      {authors.map((author) => {
        if (!author || !author.img) return null;
        return (
          <div key={author.name} className="author-description-wrapper">
            <img
              alt={`${author.name} 프로필 이미지`}
              className="author-image"
              src={`/author-profile-images/${author.img}`}
            />
            <div className="author-text-wrapper">
              <p className="author-name">
                {author.github ? (
                  <a href={author.github} target="_blank" rel="noreferrer">
                    {author.name}
                  </a>
                ) : (
                  author.name
                )}
              </p>
              <p className="author-description">{author.description}</p>
            </div>
          </div>
        );
      })}
    </section>
  );
}
