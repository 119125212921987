import { Link } from 'gatsby';
import React from 'react';
import './style.scss';

function PostHeader({ post }) {
  const authors = post.author;

  return (
    <header className="post-header">
      {post.emoji && <div className="emoji">{post.emoji}</div>}
      <h1 className="title">{post.title}</h1>
      <div className="info">
        <div className="info-top">
          <span className="author">
            by{' '}
            {authors.map((author) => {
              return (
                <strong key={author.name}>
                  {author.github ? (
                    <a href={author.github} target="_blank" rel="noreferrer">
                      {author.name}
                    </a>
                  ) : (
                    author.name
                  )}
                </strong>
              );
            })}
            ,
          </span>{' '}
          <span>{post.date}</span>
        </div>
        <div className="info-middle">{post.timeToRead} min read</div>
        <div className="categories">
          {post.categories.map((category) => (
            <Link className="category" key={category} to={`/posts/${category}`}>
              {category}
            </Link>
          ))}
        </div>
        {post.tags && (
          <div className="tags">
            {post.tags.map((tag, index) => (
              <div key={index} className="tag">
                {tag}
              </div>
            ))}
          </div>
        )}
      </div>
    </header>
  );
}
export default PostHeader;
