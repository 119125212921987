import React, { useEffect } from 'react';
import './style.scss';

function PostContent({ html }) {
  const getLatestIndex = (headersYPositions) => {
    let latestIndex = 0;

    while (latestIndex < headersYPositions.length) {
      const isWatching = headersYPositions[latestIndex] - 1 <= window.scrollY;
      if (!isWatching) {
        break;
      }
      latestIndex++;
    }

    latestIndex = latestIndex - 1;
    return latestIndex;
  };

  useEffect(() => {
    const update = (links, headersYPositions) => {
      const latestIndex = getLatestIndex(headersYPositions);
      const isFirstHeader = latestIndex === -1;
      const activeIndex = isFirstHeader ? 0 : latestIndex;

      links.forEach((el, index) =>
        el.classList[index === activeIndex ? 'add' : 'remove']('active'),
      );
    };

    const onScroll = (links, hrefs) => {
      const headersYPositions = hrefs.map((id) => {
        const { offsetTop } = document.querySelector(`[id="${id}"]`);
        return offsetTop;
      });
      update(links, headersYPositions);
    };

    const links = Array.from(document.querySelectorAll(`.table-of-contents li a`));

    const hrefs = links.map((el) => decodeURIComponent(el.getAttribute('href')).split('#')[1]);
    const handleScroll = () => onScroll(links, hrefs);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="post-content">
      <div className="markdown" dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
}

export default PostContent;
